<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">自提点列表</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>

      <!-- 筛选部分 -->
      <div class="screen">
        <div class="screen_tit">
          <div class="screen_left">
            <i class="el-icon-search"></i>
            筛选查询
          </div>
          <div class="screen_right">
            <el-button @click="screen()" size="mini">查询结果</el-button>
          </div>
        </div>
        <div class="screen_cont">
          <el-form size="small" :inline="true" class="demo-form-inline">
            <el-form-item label="选择商户：" v-show="merchantShow">
              <el-select
                v-model="business_id"
                placeholder="请选择商户"
                @change="changeMerchant(business_id)"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.business_id"
                  :label="item.business_name"
                  :value="item.business_id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!-- <el-form-item label="选择门店：" v-show="storeShow">
              <el-select v-model="storeid" placeholder="请选择门店">
                <el-option
                  v-for="item in storeList"
                  :key="item.storeid"
                  :label="item.s_name"
                  :value="item.storeid"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- 
            <el-form-item label="广告名称：">
              <el-input v-model="advert_name" placeholder="广告名称"></el-input>
            </el-form-item>

            <el-form-item label="广告位置：" prop="name">
              <el-select
                v-model="advert_position"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option
                  v-for="item in advert_position_list"
                  :key="item.key"
                  :value="item.key"
                  :label="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
      </div>

      <!-- 表格头部 -->
      <div class="table_head" v-show="allData">
        <div class="table_head_left">
          <i class="el-icon-s-unfold"></i>
          数据列表
        </div>
        <div class="table_head_right">
          <ul>
            <li>
              <el-button size="mini" v-if="addShow" @click="addAdver">
                添加自提点
              </el-button>
            </li>
          </ul>
        </div>
      </div>

      <!-- 表格渲染 -->
      <div class="table" v-show="allData">
        <!-- 设置表格高度  height="500" -->
        <el-table
          :data="tableData"
          border
          style="width: 85%; margin-left: 200px; text-align: center"
        >
          <el-table-column prop="id" label="编号" align="center" width="150">
          </el-table-column>

          <el-table-column prop="name" label="名称" align="center">
          </el-table-column>

          <el-table-column prop="mobile" label="联系方式" align="center">
          </el-table-column>

          <el-table-column
            prop="province"
            width="150"
            label="省份"
            align="center"
          >
          </el-table-column>

          <el-table-column prop="city" label="城市" align="center" width="150">
          </el-table-column>

          <el-table-column
            label="区域"
            width="150"
            align="center"
            prop="county"
          >
          </el-table-column>

          <el-table-column
            prop="address"
            label="详细地址"
            align="center"
            label-width="250px"
          >
          </el-table-column>

          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
            v-if="addShow"
          >
            <template slot-scope="scope">
              <!-- <span style="cursor: pointer" @click="see()">查看</span> -->
              <span
                style="margin-left: 10px; cursor: pointer"
                @click="
                  edit(
                    scope.row.id,
                    scope.row.name,
                    scope.row.province,
                    scope.row.city,
                    scope.row.county,
                    scope.row.mobile,
                    scope.row.business_id,
                    scope.row.address
                  )
                "
              >
                编辑
              </span>
              <span
                style="margin-left: 10px; cursor: pointer"
                @click="deleteAdver(scope.row.id)"
              >
                删除</span
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="activeListTotal"
          >
          </el-pagination>
        </div>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../../public/Frame.vue";

export default {
  data() {
    return {
      top1: "11-5",
      top2: ["11"],
      // flag: 1,
      allData: false, //渲染数据的展示
      businessList: [{}], //商户列表
      business_id: "", //选中的商户ID
      storeList: [{}], //门店列表
      storeid: "", //选中的门店ID
      addShow: true, //超管权限下隐藏添加
      admin_type: "",
      coupon_name: "", //活动名称搜索的input框的值
      active_status: "", //选择活动状态
      merchant_status: "", //选择商户
      merchantShow: false, //商户个别权限的隐藏
      storeShow: false, //门店权限的隐藏
      show: false,
      selectGoodsListShow: false, //指定商品的显示隐藏
      goodsListShow: false,
      cur_page: 0, //设置一个默认值
      addTableData: [], //优惠券商品列表
      goodsListTotal: 10, //优惠商品总条数
      tableData: [], //表格渲染数据
      coupon_id: "", //编辑时的优惠券id
      activeListTotal: 10, //表格总条数
      searchIpt: "", //优惠商品列表搜索值
      dataSelections: "", //选中的商品列表数据
      selectTableData: [], //指定商品的商品数据
      goods_type: "", //指定商品还是全部商品类型
      //总页数
      currentPage: 1,
      pageSize: 10,
      value2: true,
    };
  },
  methods: {
    //刷新页面按钮
    refresh() {
      location.reload();
    },

    //添加自提点按钮
    addAdver() {
      this.$router.push({ path: "/addLiftingPoint" });
      //请求商户列表接口
      this.$request
        .storeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
        })
        .then((res) => {
          console.log(res);
          this.storeList = res.data;
        });
    },

    //选中商户查询门店
    // changeMerchant(business_id) {
    //   console.log(business_id);
    //   this.$request
    //     .businessStoreList({
    //       token: this.$storage.getLocal("token"), //登陆进来的tooken
    //       admin_type: this.$storage.getLocal("type"), //身份
    //       business_id: business_id,
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       this.storeList = res.data;
    //     });
    // },
    //自提点列表查询按钮
    screen() {
      console.log("查询自提点列表");
      this.$request
        .selfTakeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: this.business_id, //	是	int	门店id
          limit: 10, //	是	int	一页显示几条数据默认10
          page: 1, //	是	int	第几页默认1
        })
        .then((res) => {
          console.log(res);
          this.allData = true;
          this.tableData = res.data.list;
          this.activeListTotal = res.data.count;
        });
    },
    // 设置每页多少条
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = parseInt(`${val}`);

      this.$request
        .selfTakeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: this.storeid, //	是	int	门店id
          limit: this.pageSize, //	是	int	一页显示几条数据默认10
          page: 1, //	是	int	第几页默认1
        })
        .then((res) => {
          console.log(res);
          this.allData = true;
          this.tableData = res.data.list;
          this.activeListTotal = res.data.count;
        });
    },
    // 翻页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = parseInt(`${val}`);
      console.log(1111111111111111111, this.currentPage);
      this.$request
        .selfTakeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: this.storeid, //	是	int	门店id
          limit: this.pageSize, //	是	int	一页显示几条数据默认10
          page: this.currentPage, //	是	int	第几页默认1
        })
        .then((res) => {
          console.log(res);
          this.allData = true;
          this.tableData = res.data.list;
          this.activeListTotal = res.data.count;
        });
    },

    //编辑商品按钮
    edit(id, name, province, city, county, mobile, business_id, address) {
      console.log(
        "编辑商品的默认值",
        id,
        name,
        province,
        city,
        county,
        mobile,
        business_id,
        address
      );
      this.$router.push({
        path: "/addLiftingPoint",
        query: {
          id,
          name,
          province,
          city,
          county,
          mobile,
          business_id,
          address,
        },
      });
    },

    //删除广告
    deleteAdver(id) {
      console.log(id);
      // console.log(typeof coupon_id);
      this.$request
        .deleteAdvert({
          token: this.$storage.getLocal("token"),
          advert_id: id,
        })
        .then((res) => {
          console.log(res);
          this.$request
            .advertList({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              advert_name: this.advert_name, //	是	string	广告名称
              advert_position: this.advert_position, //	是	string	广告位置
              end_time: "", //	是	string	结束时间
              business_id: this.business_id,
              store_id: this.storeid, //	是	int	门店id
              limit: this.currentPage, //	是	int	一页显示几条数据默认10
              page: this.pageSize, //	是	int	第几页默认1
            })
            .then((res) => {
              console.log(res);
              this.allData = true;
              this.tableData = res.data.data;
              this.activeListTotal = res.data.count;
            });
        });
    },
  },

  created() {
    //不同权限下的商户显示
    this.admin_type = this.$storage.getLocal("type");
    console.log("business_id", this.business_id);
    if (this.admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
      this.addShow = false;
      //请求商户列表接口
      this.$request
        .businessList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: "",
        })
        .then((res) => {
          console.log(res);
          this.businessList = res.data;
        });
      console.log("11111", this.businessList);
    }
    if (this.admin_type == 2) {
      // this.merchantShow = true;
      this.storeShow = true;
      // this.$request
      //   .businessStoreList({
      //     token: this.$storage.getLocal("token"), //登陆进来的tooken
      //     admin_type: this.$storage.getLocal("type"), //身份
      //     // business_id: business_id,
      //   })
      //   .then((res) => {
      //     console.log("帅气的我", res);
      //     // this.ruleForm.storeList = res.data;
      //     this.storeList = res.data;
      //   });
      console.log("查询自提点列表");
      this.$request
        .selfTakeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          // business_id: this.business_id, //	是	int	门店id
          limit: 10, //	是	int	一页显示几条数据默认10
          page: 1, //	是	int	第几页默认1
        })
        .then((res) => {
          console.log(res);
          this.allData = true;
          this.tableData = res.data.list;
          this.activeListTotal = res.data.count;
        });
    }
    if (this.admin_type == 3) {
      // this.merchantShow = true;
      this.storeShow = false;
      this.addShow = false;
      // this.$request
      //   .businessStoreList({
      //     token: this.$storage.getLocal("token"), //登陆进来的tooken
      //     admin_type: this.$storage.getLocal("type"), //身份
      //     // business_id: business_id,
      //   })
      //   .then((res) => {
      //     console.log("帅气的我", res);
      //     // this.ruleForm.storeList = res.data;
      //     this.storeList = res.data;
      //   });
      this.$request
        .selfTakeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          // business_id: this.business_id, //	是	int	门店id
          limit: 10, //	是	int	一页显示几条数据默认10
          page: 1, //	是	int	第几页默认1
        })
        .then((res) => {
          console.log(res);
          this.allData = true;
          this.tableData = res.data.list;
          this.activeListTotal = res.data.count;
        });
    }
  },
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 580px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
}
</style>